:root {
  --custom-color-purple: #6571ff;
  --custom-color-grey: #7987a1;
}

.stat-bar {
  --stat-bar-font-size: 1em;
  --stat-bar-progress-color: var(--custom-color-purple);
  --stat-bar-progress-bg: white;

  font-size: var(--stat-bar-font-size);

  &__text-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    gap: 20px;

    .text-muted {
      color: #999;
    }
  }

  &__progress {
    width: 100%;

    &[value] {
      -webkit-appearance:none;
      -moz-appearance:none;
      border: 0;
      appearance: none;
      height:5px;

      &::-webkit-progress-bar {
        background: var(--stat-bar-progress-bg);
      }
      &::-webkit-progress-value {
        background: var(--stat-bar-progress-color);
      }
      &::-moz-progress-bar {
        background: var(--stat-bar-progress-color);
      }
    }
  }
}

.pub-card {
  --pub-card-height: 250px;

  --pub-card-version-bg: var(--custom-color-grey);
  --pub-card-version-color: #fff;
  --pub-card-version-font-size: 12px;

  --pub-card-copy-color: #fff;

  --pub-card-control-bg: var(--custom-color-purple);
  --pub-card-control-color: #fff;
  --pub-card-control-font-size: 12px;

  position: relative;
  height: var(--pub-card-height);
  width: 100%;
  background: black;
  overflow: hidden;


  &:hover {
    .pub-card__main {
      transform: translateY(-50px);
    }
  }

  &__version {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 3;
    font-size: var(--pub-card-version-font-size);
    text-transform: uppercase;
    padding: 2px 10px;

    background: var(--pub-card-version-bg);
    color: var(--pub-card-version-color);

  }

  &__main {
    position: relative;
    display: block;
    height: 100%;
    z-index: 2;
    background: black;

    transition: transform 0.3s ease;

    &:after {
      content: '';
      width: 100%;
      height: 70%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
      z-index: 2;
    }

    &__img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &__copy {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      color: var(--pub-card-copy-color);
      margin-top: auto;
      display: block;
      z-index: 3;

      h3 {
        margin-bottom: 5px;
      }

      .stat-bar {
        --stat-bar-font-size: 12px;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &__control {
      flex-basis: 50%;
      font-size: var(--pub-card-control-font-size);
      text-transform: uppercase;
      background: var(--pub-card-control-bg);
      color: var(--pub-card-control-color);

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 10px;

      & + .pub-card__controls__control {
        --pub-card-control-bg: var(--custom-color-grey);
      }
    }
  }
}

.dashboard-grid {
  --dashboard-grid-item-height: 200px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, var(--dashboard-grid-item-height));
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  &__item {
    background: white;
    box-shadow: 0px 5px 11px -5px rgba(110,110,110,0.4);

    .card {
      box-shadow: none;
      border: 0;
      outline: 0;
      height: 100%;

      .text-muted {
        color: #999;
      }

      .card-title {
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x) 0;
        margin-bottom: 0;
      }

      .card-body {
        padding: 0 var(--bs-card-spacer-x) var(--bs-card-spacer-y);

        &.scrollable {
          overflow-y: auto;
          margin-bottom: 1rem;
        }

        .contact-modal {
          margin: 0 -.5rem;
          padding: 1rem .5rem;
          &:hover {
            background: #f4f4f4;
          }
        }
      }
    }

    &.dashboard-sales {
    }

    &.dashboard-follow-up {
      grid-column: span 1;
      grid-row: span 2;
    }

    &.dashboard-rebook {
      grid-column: span 1;
      grid-row: span 2;
    }

    &.dashboard-breakdown {
      grid-column: span 1;
      grid-row: span 3;
    }

    &.dashboard-history {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      height: var(--dashboard-grid-item-height);

      &.dashboard-sales {
      }

      &.dashboard-follow-up {
        height: calc(var(--dashboard-grid-item-height) * 2);
      }

      &.dashboard-rebook {
        height: calc(var(--dashboard-grid-item-height) * 2);
      }

      &.dashboard-breakdown {
        height: calc(var(--dashboard-grid-item-height) * 3);
      }

      &.dashboard-history {
        height: calc(var(--dashboard-grid-item-height) * 2);
      }
    }
  }
}