// Write your custom css/scss here

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

div.hidden, .selectable {
    visibility: hidden;
}

#selected-account-type {
    position: relative;
}

/* DataTable stuff */
div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_info { font-size: 12px; }
.dataTables_wrapper.dt-bootstrap4 .dataTables_length select.form-select-sm { width: 70px !important; }

.select2-container--open { z-index: 200000; }

td.clickable { cursor: pointer; }

.chat-list {
    .chat-item {

        .chat-message {
            font-size: 12px;

            .text-body {
                font-size: 14px;
            }

            ul { padding-left: 1.5rem; }

            span.customer-note {
                display: block; margin-left: 10px;
            }
        }
    }
}

.tox .tox-tbtn { height: 24px !important; width: 24px !important; }
.tox.tox-tinymce .tox-tbtn svg { scale: .8; }

.selectable {
    position: absolute;
    left: 0;
    right: 0;

    .select2-container { width: 100% !important; }
}

.selectable.on {
    visibility: visible;
}

select.readonly {
    pointer-events: none;
    background: #f4f4f4;
}

.stealth-input {

    float: right;
    /*display: none;*/

    input {
        background: transparent;
        display: inline;
        padding: 0 3px 0 0;
        text-align: right;
        border: 0;
    }

    a {
        display: inline;
    }

    &.display-only {
        display: inline;
    }
}

.text-right {
    text-align: right !important;
}

.btn-hubspot {

    border: 1px solid #ff7a59 !important;
    background: white;
    max-height: 38px;

    &:hover {
        background: #f0f0f0;
    }

    img {
        width: 65%;
    }
}

.btn-hubspot-solid {

    border: 1px solid #ff7a59 !important;
    background: #ff7a59;
    color: white;

    &:hover {
        background: #ff957b;
    }

    img {
        width: 65%;
    }
}

table.table {
    tr {
        vertical-align: middle;
    }
}

.box-creator {
    position: relative;

    > .has-create {
        position: absolute;
    }

    > .is-create {
        display: none;
        position: absolute;
        left: 0;
        right: 0;

        &.active {
            display: block;
        }
    }
}

span.required {
    color: #cc2952;
}

.grouped-selector {
    button {
        border-radius: 0 .25rem .25rem 0;
        margin-left: -3px;
    }

    .select2-container .select2-selection--single {
        border-radius: .25rem 0 0 .25rem;
    }
}

.chat-content {
    .loading {
        display: none !important;
    }
}

.chat-content {
    &.is-loading {
        > .chat-footer {
            display: none !important;
        }

        .loading {
            text-align: center;
            display: block !important;
            font-size: small;
            color: #6571ff;

            span {
                display: block;
                text-align: center;
                margin-bottom: 1em;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}

.btn-xero, .btn-hubspot {
    color: white !important;
}

.btn-outline-hubspot {
    color: $hubspot !important;
}

.btn-outline-hubspot:focus {
    color: white !important;
}

.btn-outline-xero {
    color: $xero !important;

    > * {
        color: $xero !important;
    }
}

.btn-outline-xero:hover {
    color: white !important;

    > * {
        color: white !important;
    }
}

.input-group.datepicker {
    padding: 0 !important;
}

.row.multi-dates {
    display: none;
}

.required-field.required-error {
    border-color: #c00 !important;
    background-color: rgba(200, 0, 0, .04) !important;
}

.modal.show + .modal.show {
    z-index: 1062 !important;
}

.modal-backdrop.show + .dz-hidden-input + .modal-backdrop.show,
.modal-backdrop.show + .tox + .modal-backdrop.show,
.modal-backdrop.show + .modal-backdrop.show {
    z-index: 1061 !important;
}

.modal-backdrop.show + .tox + .modal-backdrop.show + .modal-backdrop.show,
.modal-backdrop.show + .modal-backdrop.show + .modal-backdrop.show {
    z-index: 1062 !important;
}

.ajaxSaving, .ajaxSaving .modal-content * {
    cursor: wait !important;
}

.form-check-input.xero-check {
    &:checked {
        background-color: $xero;
        border-color: $xero
    }
}

form.invoiced {
    #xeroDateTags_tagsinput span.tag a, .btn-xero {
        display: none;
    }
}

#xeroDateTags_tagsinput {
    span.tag {
        background: $xero;
    }
}

.invoice-list-hidden {
    display: none;
}

input[disabled].disabled-clear {
    background: transparent !important;
    border: 0 !important;
    padding: 0 !important;
}

tr.hidden-row {
    display: none !important;
}

.modal-body {
    .tab-content-vertical {
        //height: 500px;
        //overflow-y: auto;

        &.edition-version {
            height: 410px;
            overflow-y: auto;
        }
    }
}

table.table tr > .price-col {
    width: 140px;

    &.price-gst {
        width: 100px;
    }

    &.discount {
        width: 100px;
    }
}

.card {
    &.publication-card {

        height: 250px;
        overflow: hidden;

        .card-img {
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            transition: all .5s ease-in-out;
        }

        .card-title {
            font-size: 1.5em;
        }

        .card-img-overlay {
            background: rgba(0, 0, 0, .35);
        }

        &:hover {
            .card-img {
                transform: scale(1.2)
            }
        }
    }
}

.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link {
    height: 25px;
    color: #000;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    display: block;
}

.form-loading {
    max-height: 350px;
    overflow-y: scroll;

    .loading {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .75);

        div {
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            position: absolute;
        }
    }

    &.is-loading {
        .loading {
            display: block;

        }
    }
}

.steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    display: none;
}

.step-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var($light);
    border: 1px solid var(--bs-primary);
    color: $primary;
    transition: .4s;
    margin-bottom: 5px;

    &[aria-expanded="true"] {
        width: 50px;
        height: 50px;
        background-color: var(--bs-primary);
        color: #fff;

        &.step-warning {
            background-color: #000;
            border: 4px solid $orange;
            line-height: 1;
        }
    }
}

.done {
    background-color: var(--prm-color);
    color: #fff;
}

.step-item {
    z-index: 10;
    text-align: center;

    .step-title {
        font-size: .9em;
    }
}

#progress {
    -webkit-appearance: none;
    position: absolute;
    width: 98%;
    z-index: 5;
    height: 10px;
    margin: 0 18px 18px;
}

/* to customize progress bar */
#progress::-webkit-progress-value {
    background-color: var(--prm-color);
    transition: .5s ease;
}

#progress::-webkit-progress-bar {
    background-color: var(--prm-gray);

}

#orderEmails {

    .modal-dialog-centered {
        min-height: auto !important;
        height: calc(100% - 3.5rem);
    }

    .modal-content {
        height: 670px;
    }

    .col-lg-9 {
        position: relative;
    }

    .inbox-wrapper {

        .sent-items,
        .reading,
        .composer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.mode-compose {
            .sent-items, .reading {
                display: none;
            }

            .composer {
                display: block;
            }
        }

        &.mode-reading {
            .sent-items, .composer {
                display: none;
            }

            .reading {
                display: block;
            }
        }

        &.mode-sent {
            .sent-items {
                display: block;
            }

            .composer, .reading {
                display: none;
            }
        }
    }
}

.email-content {

    padding: 1.2em;
    max-height: 493px;
    overflow-y: scroll;

    body, table, td, th, div, h1, p {
        font-family: Helvetica, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
    }

    th {
        font-weight: bold;
    }

    table, p {
        margin: 0 0 12px 0;
    }
}

.edition-dropdown a input {
    pointer-events: none;
}

.edition-dropdown, .edition-months {
    width: 100%;
}

.tab-content-vertical {
    //.tab-pane.active,
    .table-responsive {
        height: 100%;
    }
}

.product-price {
    &-gst {
        width: 80px;
    }
}

.product-discount {
    width: 80px;
    margin-left: auto;
}

.hidden-row-link {
    color: #ff3366;
}

.hidden-rows {
    display: none;
}

.show-file {
    display: none;
}

.has-file {
    input[type="file"] {
        display: none;
    }

    .show-file {
        display: flex;
    }
}

.print-digital-tabs {
    .tab-pane {
        height: 500px;
        overflow-y: auto
    }
}

#p-packages {
    height: 539px;
    overflow-y: auto
}

.form-control {

    &[readonly] {
        background-color: #f4f4f4;
    }
}

.drag-and-drop {
    #ddArea {
        height: 200px;
        border: 2px dashed #ccc;
        line-height: 200px;
        text-align: center;
        font-size: 20px;
        background: #f9f9f9;
        margin-bottom: 15px;
    }

    .drag_over {
        color: #000;
        border-color: #000;
    }

    .thumbnail {
        width: 100px;
        height: 100px;
        padding: 2px;
        margin: 2px;
        border: 2px solid lightgray;
        border-radius: 3px;
        float: left;
    }

    .d-none {
        display: none;
    }

    .d-block {
        display: block;
    }

    /* Absolute Center Spinner */
    .loading {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: visible;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    /* Transparent Overlay */
    .loading:before {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

// Chart fixes //
#breakdownContainer .apexcharts-tooltip {
  color: #fff !important;
}

small.overflow {
    max-width: 300px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #666;
    font-style: italic;
}

.card.edition-card {
    height: 215px;

    h5 {
        line-height: 2.2;
    }

    .ed-scroll {
        height: 110px;
        overflow-y: scroll;
    }
}

.card.edition-card {
    height: 260px;

    h5 {
        line-height: 2.2;
    }
}

.edition-wrapper {
    .card.edition-card {
        height: 215px;

        h5 {
            line-height: 2.2;
        }

        .ed-scroll {
            height: 110px;
            overflow-y: scroll;
        }
    }
}

.do-transparent {
    &.is-transparent {
        color: transparent !important;
    }
}

.apexcharts-tooltip-series-group { background: rgba(255,255,255,.9); }

#compare-order-line .status-filters .badge { pointer-events: none; opacity: .4; }

.status-filters {

    padding: 8px 0;

    span {
        margin-right: 10px;

        &.badge {
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.bg-dark {
                background: #060c17 !important;
                outline: 1px solid #060c17;

                &.is-off {
                    background: #fff !important;
                    color: #060c17 !important;
                }
            }

            &.bg-primary {
                background: #6571ff !important;
                outline: 1px solid #6571ff;

                &.is-off {
                    background: #fff !important;
                    color: #6571ff !important;
                }
            }

            &.bg-xero {
                background: #13b5ea !important;
                outline: 1px solid #13b5ea;

                &.is-off {
                    background: #fff !important;
                    color: #13b5ea !important;
                }
            }

            &.bg-danger {
                background: #ff3366 !important;
                outline: 1px solid #ff3366;

                &.is-off {
                    background: #fff !important;
                    color: #ff3366 !important;
                }
            }
        }
    }
}

em.months-selected {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.create-the-contract { margin-left: auto; }

.use-xero {
    color: #13b5ea;
    float: right;
    padding-right: 3px;
    svg {
        height: 14px;
        width: 14px;
    }
}
.bs-tooltip-auto { z-index: 11111111; }

@import "dashboard";

.table {
    --bs-table-bg: white;
}

[data-invoice] .badge { font-size: 12px !important; }


@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.validate-xero {
    &.validating {
        pointer-events: none;

        svg {
            animation: rotating 2s linear infinite;
        }
    }
}

.account-row {
    &.row-error > * {
        background-color: #ffeef0 !important;
    }
    &.row-ok > * {
        background-color: #efffee !important;
    }

    .validate-result {
        font-size: 11px !important;
        margin-top: 5px;
        pre {
            padding-top: 0;
            font-size: 11px;
        }
    }
}
